
import { defineComponent } from "vue";
import {
  IonPage,
  IonContent,
  IonItem,
  IonLabel,
  IonInput,
  IonText,
  IonButton,
  toastController,
} from "@ionic/vue";
import axios from "axios";

export default defineComponent({
  name: "Register",
  data() {
    return {
      namaLengkap: "",
      noHp: "",
      email: "",
      password: "",
      alamat: "",
      pekerjaan: "",
      btnLoading: false,
      lat: 0,
      long: 0,
    };
  },
  ionViewDidEnter() {    
    this.getLocation();
  },
  methods: {
    async onRegister() {
      const formData = new FormData();
      formData.append("nama", this.namaLengkap);
      formData.append("hp", this.noHp);
      formData.append("email", this.email);
      formData.append("alamat", this.alamat);
      formData.append("pekerjaan", this.pekerjaan);
      formData.append("password", this.password);
      this.$route.query.ref && formData.append("ref", this.$route.query.ref);
      this.lat && formData.append("latitude", this.lat.toString());
      this.long && formData.append("longitude", this.long.toString());
      this.btnLoading = true;
      await axios
        .post("signup", formData)
        .then(async ({ data }) => {
          this.btnLoading = false;
          const token = data.data.accessToken;
          console.log(token);
          
          if (token) {
            window.$cookies.set("accessToken", token);
            localStorage.setItem("id_user_regis", data.data.id_user);
            return this.$router.replace("/auth/otp");
          } else {
            const toast = await toastController.create({
              message: "Terjadi kesalahan terkait server",
              duration: 2000,
            });
            return toast.present();
          }
        })
        .catch(async ({ response }) => {
          this.btnLoading = false;
          const toast = await toastController.create({
            message: response.data.message,
            duration: 2000,
          });
          toast.present();
        });
    },
    getLocation() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.lat = position.coords.latitude;
        this.long = position.coords.longitude;
      });
    },
  },
  components: {
    IonPage,
    IonContent,
    IonItem,
    IonLabel,
    IonInput,
    IonText,
    IonButton,
  },
});
